<template>
  <section>
    <div class="container">
      <form class="rating-form">
        <div
          id="option_1"
          class="rating"
        >
          <label for="option_1">
            <div class="media">
              <div class="media-aside align-self-start">
                <img
                  src="public/init/banner.jpg"
                  alt="placeholder"
                  width="64"
                  class="rounded-circle"
                >
              </div><div
                class="media-body"
              >
                <h4
                  class="media-heading"
                >
                  Cookie candy
                </h4><p
                  class="card-text mb-0"
                >
                  Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy.
                  Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy.
                  Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy.
                  Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy.
                </p>
              </div>
            </div>
            <button
              v-if="voted === false && selected !== 'option_1'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="valueChoice('option_1')"
            > Selecionar </button>
            <button
              v-if="selected === 'option_1'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="confirm('option_1')"
            >
              Confirmar
            </button>
          </label>
        </div>
        <div
          id="option_2"
          class="rating"
        >
          <label for="option_2">
            <div class="media">
              <div class="media-aside align-self-start">
                <img
                  src="public/init/banner.jpg"
                  alt="placeholder"
                  width="64"
                  class="rounded-circle"
                >
              </div><div
                class="media-body"
              >
                <h4
                  class="media-heading"
                >
                  Cookie candy
                </h4><p
                  class="card-text mb-0"
                >
                  Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy.
                </p>
              </div>
            </div>
            <button
              v-if="voted === false && selected !== 'option_2'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="valueChoice('option_2')"
            > Selecionar </button>
            <button
              v-if="selected === 'option_2'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="confirm('option_2')"
            >
              Confirmar
            </button>
          </label>
        </div>
        <div
          id="option_3"
          class="rating"
        >
          <label for="option_3">
            <div class="media">
              <div class="media-aside align-self-start">
                <img
                  src="public/init/banner.jpg"
                  alt="placeholder"
                  width="64"
                  class="rounded-circle"
                >
              </div><div
                class="media-body"
              >
                <h4
                  class="media-heading"
                >
                  Cookie candy
                </h4><p
                  class="card-text mb-0"
                >
                  Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy.
                </p>
              </div>
            </div>
            <button
              v-if="voted === false && selected !== 'option_3'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="valueChoice('option_3')"
            > Selecionar </button>
            <button
              v-if="selected === 'option_3'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="confirm('option_3')"
            >
              Confirmar
            </button>
          </label>
        </div>
        <div
          id="option_4"
          class="rating"
        >
          <label for="option_4">
            <div class="media">
              <div class="media-aside align-self-start">
                <img
                  src="public/init/banner.jpg"
                  alt="placeholder"
                  width="64"
                  class="rounded-circle"
                >
              </div><div
                class="media-body"
              >
                <h4
                  class="media-heading"
                >
                  Cookie candy
                </h4><p
                  class="card-text mb-0"
                >
                  Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy.
                </p>
              </div>
            </div>
            <button
              v-if="voted === false && selected !== 'option_4'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="valueChoice('option_4')"
            > Selecionar </button>
            <button
              v-if="selected === 'option_4'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="confirm('option_4')"
            >
              Confirmar
            </button>
          </label>
        </div>
        <div
          id="option_5"
          class="rating"
        >
          <label for="option_5">
            <div class="media">
              <div class="media-aside align-self-start">
                <img
                  src="public/init/banner.jpg"
                  alt="placeholder"
                  width="64"
                  class="rounded-circle"
                >
              </div><div
                class="media-body"
              >
                <h4
                  class="media-heading"
                >
                  Cookie candy
                </h4><p
                  class="card-text mb-0"
                >
                  Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy.
                </p>
              </div>
            </div>
            <button
              v-if="voted === false && selected !== 'option_5'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="valueChoice('option_5')"
            > Selecionar </button>
            <button
              v-if="selected === 'option_5'"
              type="button"
              name="rating"
              class="center btn btn-primary"
              @click="confirm('option_5')"
            >
              Confirmar
            </button>
          </label>
        </div>
      </form>
      <!-- modal backdrop -->
      <b-modal
        id="modal-no-backdrop"
        ref="my-modal"
        ok-only
        hide-footer
        hide-header-close
        content-class="shadow"
        no-close-on-backdrop
        title="Obrigado por participar"
        ok-title="Accept"
      >
        <span style="font-size: 40px; text-align: center">Já computamos sua votação</span>

        <b-card-text style="text-align: center">
          <feather-icon
            color="#47AC40"
            icon="SaveIcon"
            size="100"
          />
        </b-card-text>
        <b-card-text style="text-align: center">
          <button
            block
            variant="outline-primary"
            class="btn btn-success"
            @click="home()"
          >
            Inicio
          </button>
        </b-card-text>
      </b-modal>
      <!-- modal backdrop -->
      <b-modal
        id="modal-no-backdrop"
        ref="my-modal-after-vote"
        ok-only
        hide-footer
        hide-header-close
        content-class="shadow"
        no-close-on-backdrop
        title="Obrigado por participar"
        ok-title="Accept"
      >
        <span style="font-size: 40px; text-align: center">Seu voto foi computado com sucesso!</span>
        <b-card-text style="text-align: center">
          <feather-icon
            color="#47AC40"
            icon="SaveIcon"
            size="100"
          />
        </b-card-text>
        <b-card-text style="text-align: center">
          <button
            block
            variant="outline-primary"
            class="btn btn-success"
            @click="home()"
          >
            Inicio
          </button>
        </b-card-text>
      </b-modal>
    </div>
  </section>
</template>

<script>
import VoteServices from './voteServices'
import {
  BButton, BCol, BRow, ModalPlugin
} from 'bootstrap-vue'

export default {
  components: {
  },
  data() {
    return {
      choice: '',
      exemple: '',
      selected: '',
      voted: false,
    }
  },
  computed: {
    userState() {
      return this.$store.state.auth.user
    }
  },
  watch: {},
  mounted() {
    VoteServices().voteCheck().then(res => {
      if (res.data.response.voted === true) {
        this.$refs['my-modal'].show()
        this.voted = true
      }
    })
  },
  methods: {
    valueChoice(x) {
      this.choice = x
      console.log(this.choice)
      document.getElementById('option_1').style.backgroundColor = '#f2f3f4'
      document.getElementById('option_1').style.borderColor = '#2197f2'
      document.getElementById('option_2').style.backgroundColor = '#f2f3f4'
      document.getElementById('option_2').style.borderColor = '#2197f2'
      document.getElementById('option_3').style.backgroundColor = '#f2f3f4'
      document.getElementById('option_3').style.borderColor = '#2197f2'
      document.getElementById('option_4').style.backgroundColor = '#f2f3f4'
      document.getElementById('option_4').style.borderColor = '#2197f2'
      document.getElementById('option_5').style.backgroundColor = '#f2f3f4'
      document.getElementById('option_5').style.borderColor = '#2197f2'

      document.getElementById(this.choice).style.backgroundColor = '#a5d4a4'
      document.getElementById(this.choice).style.borderColor = '#348d36'
      this.selected = this.choice
    },
    async confirm(selected) {
      const response = await VoteServices().voteCreate({
        user_id: this.userState.id,
        selected
      })
      if (response.data.status === 200) {
        this.$refs['my-modal-after-vote'].show()
      }
    },
    home() {
      window.location.href = '/'
    }
  },
}
</script>
<style scoped>
.rating {
  width: 300px;
  height: 250px;
  position: relative;
  margin: 15px;
  border-color: #2196F3;
  background-color: #f2f3f4;
  border-left: 6px solid #2197f2;
  border-radius: 5px;
  float: left;
}
.container:before,
.container:after {
    content: "";
    display: table;
}
.container:after {
    clear: both;
}
.center {
  margin: 0;
  position: absolute;
  top: 90%;
  left: 50%;
  margin-left: -.5em;
  margin-top: -2em;
}
p {
  height: 12em;
  overflow: auto;
  }
</style>
